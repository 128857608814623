import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';
import logoUrl from '../assets/images/SCOOPSCAID01.png';

export default function Contact() {
  // const form = useRef();
  const form = useRef<HTMLFormElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (form.current) {
      form.current.reset(); // Access the form safely
    }

    const templateParams = {
      to_email: (form.current as any).email.value,
      from_name: (form.current as any).name.value,
      message: (form.current as any).message.value,
      logo_url: logoUrl
    };

    emailjs.send(
      'service_pellmeu',
      'template_fxx77r6',
      templateParams,
      'k6wtl6fI7iBuJ9rIj'
    )
      .then(() => {
        toast.success('Message envoyé avec succès!');
        (form.current as unknown as HTMLFormElement).reset();
      })
      .catch(() => {
        toast.error('Erreur lors de l\'envoi du message.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section id="contact" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-700 text-center mb-12">
          Contact
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Bloc d'informations de contact */}
          <div className="bg-orange-50 p-8 rounded-lg">
            <h3 className="text-2xl font-semibold text-green-700 mb-6">
              Nos Coordonnées
            </h3>
            <div className="space-y-6">
              <div>
                <h4 className="text-lg font-medium text-orange-500 mb-2">Adresse</h4>
                <p className="text-gray-600">
                  Baoulékro<br />
                  Abengourou, Côte d'Ivoire
                </p>
              </div>
              <div>
                <h4 className="text-lg font-medium text-orange-500 mb-2">Téléphone</h4>
                <p className="text-gray-600">
                  Mobile: +225 07 97 29 65 39 / +225 07 02 72 72 61<br />
                  WhatsApp: +225 07 97 29 65 39
                </p>
              </div>
              <div>
                <h4 className="text-lg font-medium text-orange-500 mb-2">Emails</h4>
                <p className="text-gray-600">contact@scoopscaid.com / contact@agrinego.com</p>
              </div>
              <div>
                <h4 className="text-lg font-medium text-orange-500 mb-2">Horaires</h4>
                <p className="text-gray-600">
                  Lundi - Vendredi: 8h00 - 18h00<br />
                  Samedi: 9h00 - 13h00
                </p>
              </div>
            </div>
          </div>

          {/* Formulaire de contact */}
          <div>
            <form ref={form} onSubmit={sendEmail} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-green-700 mb-2">Nom</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-orange-500 focus:border-orange-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-green-700 mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-orange-500 focus:border-orange-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-green-700 mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  required
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-orange-500 focus:border-orange-500"
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 disabled:bg-orange-300 disabled:cursor-not-allowed"
              >
                {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </section>
  );
}