import React from 'react';
import heroImage from '../assets/images/hero-farm.jpg';

export default function Hero() {
  return (
    <section id="accueil" className="pt-20">
      <div className="relative h-[400px]">
        <img 
          src={heroImage} 
          alt="Agriculture" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-center text-white px-4">
            <h1 className="text-5xl font-bold mb-6">
              Excellence en Négoce Agricole
            </h1>
            <p className="text-xl mb-8">
              Spécialiste en cacao, café, anacarde et igname
            </p>
            <button className="bg-orange-500 text-white px-8 py-3 rounded-lg hover:bg-orange-600">
              Découvrir nos produits
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-20">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold text-green-700 mb-6">À Propos de Nous</h2>
            <p className="text-gray-600 mb-4">
              Depuis plus de 20 ans, notre entreprise s'est imposée comme un acteur majeur dans le négoce de produits agricoles en Afrique de l'Ouest. En partenariat avec <a href='https://agrinego.com/' target="_blank" rel="www.agrinego.com" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">AGRI NEGO</a>, nous avons renforcé notre engagement envers la qualité et le respect des normes internationales, ce qui nous a permis de gagner la confiance de nos partenaires à travers le monde.
            </p>
            <p className="text-gray-600 mb-4">
            Ensemble, nous travaillons en étroite collaboration avec les producteurs locaux pour garantir des pratiques agricoles durables et un commerce équitable. Cette synergie nous permet d’exceller dans la sélection et le contrôle qualité, offrant ainsi les meilleurs produits à nos clients.
            </p>
            <div className="grid grid-cols-2 gap-4 mt-8">
              <div className="bg-orange-50 p-4 rounded-lg text-center">
                <h3 className="text-2xl font-bold text-orange-500">20+</h3>
                <p className="text-gray-600">Années d'expérience</p>
              </div>
              <div className="bg-orange-50 p-4 rounded-lg text-center">
                <h3 className="text-2xl font-bold text-orange-500">1000+</h3>
                <p className="text-gray-600">Producteurs partenaires</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div className="bg-green-700 text-white p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Notre Mission</h3>
                <p>Promouvoir l'excellence agricole africaine sur le marché mondial</p>
              </div>
              <div className="bg-orange-500 text-white p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Nos Valeurs</h3>
                <p>Qualité, durabilité, intégrité et équité</p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="bg-green-700 text-white p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Innovation</h3>
                <p>Adoption des meilleures pratiques du secteur</p>
              </div>
              <div className="bg-orange-500 text-white p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-2">Engagement</h3>
                <p>Développement des communautés locales</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}