import React, { useState } from 'react';
import cacaoImage from '../assets/images/cacao-recolte.jpg';
import cafeImage from '../assets/images/cafe03.jpg';
import anacardeImage from '../assets/images/anacarde-marche.jpg';
import ignameImage from '../assets/images/igname.jpg';
import ProductModal from './ProductModal';

interface ProductDetails {
  origine: string;
  qualite: string;
  certification: string;
  description_longue: string;
}

interface Product {
  name: string;
  description: string;
  image: string;
  details: ProductDetails;
}

const products: Product[] = [
  {
    name: 'Cacao',
    description: 'Fèves de cacao de première qualité',
    image: cacaoImage,
    details: {
      origine: 'Côte d\'Ivoire',
      qualite: 'Grade A',
      certification: 'Commerce équitable',
      description_longue: 'Nos fèves de cacao sont soigneusement sélectionnées et proviennent des meilleures régions productrices. Nous garantissons une traçabilité complète et un respect des normes internationales.'
    }
  },
  {
    name: 'Café',
    description: 'Café premium sélectionné avec soin',
    image: cafeImage,
    details: {
      origine: 'Régions montagneuses',
      qualite: 'Arabica Premium',
      certification: 'Bio',
      description_longue: 'Notre café est cultivé en altitude, ce qui lui confère des arômes complexes et une qualité exceptionnelle. Chaque lot est rigoureusement contrôlé.'
    }
  },
  {
    name: 'Anacarde',
    description: 'Noix de cajou de haute qualité',
    image: anacardeImage,
    details: {
      origine: 'Afrique de l\'Ouest',
      qualite: 'Premium',
      certification: 'ISO 22000',
      description_longue: 'Nos noix de cajou sont transformées selon les plus hauts standards de qualité. Nous garantissons un produit d\'exception pour nos clients.'
    }
  },
  {
    name: 'Igname',
    description: 'Ignames fraîches et sélectionnées',
    image: ignameImage,
    details: {
      origine: 'Culture locale',
      qualite: 'Catégorie 1',
      certification: 'Standard local',
      description_longue: 'Nos ignames sont cultivées localement et sélectionnées pour leur qualité exceptionnelle. Nous assurons une fraîcheur optimale tout au long de l\'année.'
    }
  }
];

export default function Products() {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  return (
    <section id="produits" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-700 text-center mb-12">
          Nos Produits
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.name} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
              <div className="aspect-w-16 aspect-h-9">
                <img 
                  src={product.image} 
                  alt={product.name}
                  className="w-full h-64 object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-orange-500 mb-2">{product.name}</h3>
                <p className="text-gray-600 mb-4">{product.description}</p>
                <button
                  onClick={() => setSelectedProduct(product)}
                  className="w-full bg-green-700 text-white py-2 rounded-lg hover:bg-green-800 transition-colors"
                >
                  Voir les détails
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
}