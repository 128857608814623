import React from 'react';
import logoImage from '../assets/images/SCOOPS CAID 02.png';

export default function Footer() {
  return (
    <footer className="bg-green-700 text-white py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4"><img src={logoImage} alt="Logo AgroSeb" className="h-10 w-auto" /></h3>
            <p>Votre partenaire de confiance dans le négoce agricole</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact</h3>
            <p>
              Emails:<br />
              contact@scoopscaid.com / contact@agrinego.com
            </p>
            <p>
              Mobile: +225 07 97 29 65 39 / +225 07 02 72 72 61<br />
              WhatsApp: +225 07 97 29 65 39
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Suivez-nous</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-orange-500">Facebook</a>
              <a href="#" className="hover:text-orange-500">Instagram</a>
              <a href="#" className="hover:text-orange-500">LinkedIn</a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-green-600 text-center">
          <p>&copy; 2024 scoops caid. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
}