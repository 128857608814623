import React from 'react';
import { Link } from 'react-router-dom';
import cacaoRecolteImage from '../assets/images/cacao-recolte.jpg';
import cafeCultureImage from '../assets/images/cafe-culture.jpg';
import anacardeMarcheImage from '../assets/images/anacarde01.jpg';

const posts = [
  {
    slug: 'recolte-cacao',
    title: 'La Récolte du Cacao',
    excerpt: 'Découvrez le processus de récolte du cacao de qualité...',
    date: '2024-01-15',
    image: cacaoRecolteImage
  },
  {
    slug: 'culture-cafe',
    title: 'Culture du Café',
    excerpt: 'Les meilleures pratiques pour la culture du café...',
    date: '2024-01-10',
    image: cafeCultureImage
  },
  {
    slug: 'marche-anacarde',
    title: 'Marché de l\'Anacarde',
    excerpt: 'Tendances actuelles du marché de l\'anacarde...',
    date: '2024-01-05',
    image: anacardeMarcheImage
  }
];

export default function Blog() {
  return (
    <section id="blog" className="py-20 bg-orange-50">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-green-700 text-center mb-12">
          Blog
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {posts.map((post) => (
            <div key={post.slug} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="aspect-w-16 aspect-h-9">
                <img 
                  src={post.image} 
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-orange-500 mb-2">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-green-700">{post.date}</span>
                  <Link
                    to={`/blog/${post.slug}`}
                    className="text-orange-500 hover:text-orange-600 font-semibold"
                  >
                    Lire la suite →
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}