import React from 'react';
import { useParams } from 'react-router-dom';
import cacaoImage from '../assets/images/cacao-recolte.jpg';
import cafeImage from '../assets/images/cafe-culture.jpg';
import anacardeImage from '../assets/images/anacarde-marche.jpg';

const blogPosts = {
  'recolte-cacao': {
    title: 'La Récolte du Cacao',
    date: '2024-01-15',
    image: cacaoImage,
    content: `
      La récolte du cacao est une étape cruciale qui demande expertise et précision. 
      Nos producteurs suivent un processus rigoureux pour garantir la meilleure qualité possible.

      Le processus commence par la sélection des cabosses mûres, reconnaissables à leur couleur 
      caractéristique. La récolte se fait à la main, avec des outils adaptés pour ne pas 
      endommager l'arbre.

      Après la récolte, les cabosses sont ouvertes avec précaution pour en extraire les fèves. 
      Ces dernières sont alors fermentées pendant plusieurs jours, une étape essentielle pour 
      développer les arômes caractéristiques du cacao.

      Le séchage qui suit est tout aussi important. Les fèves sont exposées au soleil pendant 
      plusieurs jours, tout en étant régulièrement remuées pour assurer un séchage uniforme.
    `
  },
  'culture-cafe': {
    title: 'Culture du Café',
    date: '2024-01-10',
    image: cafeImage,
    content: `
      La culture du café est un art qui demande patience et savoir-faire. Nos plantations 
      sont situées dans des zones climatiques idéales pour la production de café de qualité.

      Les caféiers sont cultivés sous ombrage, une méthode traditionnelle qui favorise 
      la biodiversité et permet d'obtenir des grains de meilleure qualité. L'irrigation 
      est soigneusement contrôlée pour optimiser la croissance des plants.

      La récolte se fait uniquement lorsque les cerises sont parfaitement mûres, 
      garantissant ainsi une qualité optimale. Chaque cerise est cueillie à la main, 
      un processus qui demande du temps mais qui assure la meilleure sélection possible.
    `
  },
  'marche-anacarde': {
    title: 'Marché de l\'Anacarde',
    date: '2024-01-05',
    image: anacardeImage,
    content: `
      Le marché de l'anacarde connaît une croissance constante ces dernières années. 
      Notre expertise dans ce domaine nous permet de fournir des produits de première qualité 
      à nos clients internationaux.

      La production d'anacarde nécessite une attention particulière aux conditions de culture 
      et de récolte. Nos producteurs suivent des protocoles stricts pour garantir la qualité 
      des noix.

      Le traitement post-récolte est tout aussi important. Les noix sont triées, calibrées 
      et conditionnées selon les standards internationaux les plus exigeants.
    `
  }
};

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const post = blogPosts[slug as keyof typeof blogPosts];

  if (!post) {
    return <div className="text-center py-20">Article non trouvé</div>;
  }

  return (
    <article className="py-20 bg-white">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-green-700 mb-4">{post.title}</h1>
        <p className="text-gray-500 mb-8">{post.date}</p>
        
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-96 object-cover rounded-lg mb-8"
        />
        
        <div className="prose prose-lg max-w-none">
          {post.content.split('\n\n').map((paragraph: string, index: number) => (
            <p key={index} className="text-gray-600 mb-4">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </article>
  );
}