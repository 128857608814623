import React from 'react';

interface ProductDetails {
  origine: string;
  qualite: string;
  certification: string;
  description_longue: string;
}

interface Product {
  name: string;
  image: string;
  details: ProductDetails;
}

interface ProductModalProps {
  product: Product;
  onClose: () => void;
}

export default function ProductModal({ product, onClose }: ProductModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold text-green-700">{product.name}</h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <img
            src={product.image}
            alt={product.name}
            className="w-full h-64 object-cover rounded-lg mb-4"
          />
          
          <div className="space-y-4">
            <div>
              <h4 className="text-lg font-semibold text-orange-500">Origine</h4>
              <p className="text-gray-600">{product.details.origine}</p>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold text-orange-500">Qualité</h4>
              <p className="text-gray-600">{product.details.qualite}</p>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold text-orange-500">Certification</h4>
              <p className="text-gray-600">{product.details.certification}</p>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold text-orange-500">Description détaillée</h4>
              <p className="text-gray-600">{product.details.description_longue}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}