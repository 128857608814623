import React, { useState } from 'react';
import logoImage from '../assets/images/SCOOPSCAID01.png';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <img src={logoImage} alt="Logo AgroSeb" className="h-10 w-auto" />
          </div>

          {/* Menu Desktop */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="#accueil" className="text-green-700 hover:text-orange-500">Accueil</a>
            <a href="#produits" className="text-green-700 hover:text-orange-500">Nos produits</a>
            <a href="#blog" className="text-green-700 hover:text-orange-500">Blog</a>
            <a href="#contact" className="text-green-700 hover:text-orange-500">Contact</a>
          </div>

          {/* Bouton Menu Mobile */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-green-700 hover:text-orange-500 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Menu Mobile */}
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-t">
              <a
                href="#accueil"
                className="block px-3 py-2 text-green-700 hover:text-orange-500 hover:bg-orange-50 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                Accueil
              </a>
              <a
                href="#produits"
                className="block px-3 py-2 text-green-700 hover:text-orange-500 hover:bg-orange-50 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                Nos produits
              </a>
              <a
                href="#blog"
                className="block px-3 py-2 text-green-700 hover:text-orange-500 hover:bg-orange-50 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                Blog
              </a>
              <a
                href="#contact"
                className="block px-3 py-2 text-green-700 hover:text-orange-500 hover:bg-orange-50 rounded-md"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}